import { toString, Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { LookupResponseModule_ToSelectItems, ReportRequest, ApiMessageResponse_$reflection, SelectItem_$reflection, UserResponse_$reflection, PagedDataResponse$1_$reflection, OnHandDetailsReportResponse_$reflection } from "../../FootPrint.Portal.DTOs/DTOs.fs.js";
import { AsyncMsg$1, Deferred$1, Deferred_exists, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { union_type, record_type, int32_type, option_type, bool_type, list_type, unit_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { StateSelectFilterHelper_renderSearch, StateSelectFilterHelper_extractTypeAheadFilter, StateSelectFilter, TypeStateHeld, StateSelectFilterHelper_tryGet, StateSelectFilterHelper_extractMultiSelectFilter, StateSelectFilterModule_addTypeAheadSearchFilter, StateSelectFilterModule_addMultiSearchFilter, TypeStateHeldModule_toTypeAheadSearchState, TypeStateHeldModule_toMultiSearchState, StateSelectFilterHelper_selectedItemsIdsAsStrings, StateSelectFilterHelper_selectedItemsDisplayValues, StateSelectFilterHelper_selectedItemsIds, StateSelectFilter_$reflection } from "../Utilities/StateSelectFilterHelper.fs.js";
import { render as render_3, update as update_4, init as init_4, Msg_$reflection as Msg_$reflection_1, State_$reflection as State_$reflection_1 } from "../Components/ExportReportDialog.fs.js";
import { render as render_2, update as update_3, init as init_3, Msg_$reflection as Msg_$reflection_4, State_$reflection as State_$reflection_2 } from "../Components/SelectComponent.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { render as render_1, State as State_3, update as update_1, extractName, init as init_1, SelectedItemsChangedData_$reflection, Msg_$reflection as Msg_$reflection_2 } from "../Components/MultiSelectSearch.fs.js";
import { render as render_4, update as update_2, extractName as extractName_1, init as init_2, Msg_$reflection as Msg_$reflection_3 } from "../Components/TypeAheadSearch.fs.js";
import { DataSource, DataSourceItem, Default_pagedData, SearchFilterKey, DataSource_$reflection } from "../Models.fs.js";
import { parse } from "../fable_modules/fable-library.4.10.0/Int32.js";
import { createObj, comparePrimitives, numberHash, int32ToString, equals, defaultOf } from "../fable_modules/fable-library.4.10.0/Util.js";
import { getSupplierSKUs, getMaterials, getOwners, getProjects } from "../API/DataSourceAPI.fs.js";
import { userHasClaim } from "../Components/ClaimHelpers.fs.js";
import { isEmpty, filter as filter_3, empty, map, singleton, ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { Cmd_none, Cmd_map, Cmd_batch } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { commonSearchProjects } from "../Utilities/UpdateUtilities.fs.js";
import { Toast_text, Toast_title, Toast_info, Alert_texts, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.1.0/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { exportOnHandDetails, getOnHandDetails } from "../API/ReportAPI.fs.js";
import { contains, map as map_1, sortBy } from "../fable_modules/fable-library.4.10.0/Array.js";
import { createElement } from "react";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { map as map_2, empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { render as render_5 } from "../Components/Loader.fs.js";
import { formatDateTime, formatDecimal } from "../Domain/Formatter.fs.js";

export class State extends Record {
    constructor(PagedDataDeferred, CurrentUser, ExportDeferred, SelectFilters, ExportReportState, ShowProjectSearch, SelectedWarehouse, WarehouseSelectState, SupplierSelectState, Page) {
        super();
        this.PagedDataDeferred = PagedDataDeferred;
        this.CurrentUser = CurrentUser;
        this.ExportDeferred = ExportDeferred;
        this.SelectFilters = SelectFilters;
        this.ExportReportState = ExportReportState;
        this.ShowProjectSearch = ShowProjectSearch;
        this.SelectedWarehouse = SelectedWarehouse;
        this.WarehouseSelectState = WarehouseSelectState;
        this.SupplierSelectState = SupplierSelectState;
        this.Page = (Page | 0);
    }
}

export function State_$reflection() {
    return record_type("FootPrint.Portal.UI.Pages.OnHandDetailsQuery.State", [], State, () => [["PagedDataDeferred", Deferred$1_$reflection(PagedDataResponse$1_$reflection(OnHandDetailsReportResponse_$reflection()))], ["CurrentUser", UserResponse_$reflection()], ["ExportDeferred", Deferred$1_$reflection(unit_type)], ["SelectFilters", list_type(StateSelectFilter_$reflection())], ["ExportReportState", State_$reflection_1()], ["ShowProjectSearch", bool_type], ["SelectedWarehouse", option_type(SelectItem_$reflection())], ["WarehouseSelectState", State_$reflection_2()], ["SupplierSelectState", State_$reflection_2()], ["Page", int32_type]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["QueryAsync", "ExportAsync", "ExportReportMsg", "MultiSelectSearchMsg", "SetMultiSearchItems", "TypeAheadSearchMsg", "SetTypeAheadSearchItems", "SetSelectedWarehouses", "RebuildProjectFilters", "SetNextPage", "SetPreviousPage"];
    }
}

export function Msg_$reflection() {
    return union_type("FootPrint.Portal.UI.Pages.OnHandDetailsQuery.Msg", [], Msg, () => [[["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedDataResponse$1_$reflection(OnHandDetailsReportResponse_$reflection()), ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", PagedDataResponse$1_$reflection(OnHandDetailsReportResponse_$reflection())]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["Item", Msg_$reflection_1()]], [["Item", Msg_$reflection_2()]], [["Item", SelectedItemsChangedData_$reflection()]], [["Item", Msg_$reflection_3()]], [["Item", DataSource_$reflection()]], [["Item", Msg_$reflection_4()]], [], [], []]);
}

function hasPreviousPage(state) {
    return state.Page > 1;
}

function hasNextPage(state) {
    return Deferred_exists((pagedData) => ((state.Page * pagedData.PageSize) < pagedData.TotalCount), state.PagedDataDeferred);
}

function buildReportRequest(state) {
    let matchValue_1;
    let Description;
    const matchValue = state.ExportReportState.ReportDescription;
    Description = ((matchValue != null) ? matchValue : "");
    const OwnerIds = StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(2, [])));
    const ProjectIds = StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(1, [])));
    const MaterialIds = StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(3, [])));
    return new ReportRequest("OnHandDetails.xlsx", Description, ProjectIds, OwnerIds, new Int32Array(0), (matchValue_1 = state.SelectedWarehouse, (matchValue_1 != null) ? (new Int32Array([parse(matchValue_1.Id, 511, false, 32)])) : (new Int32Array([]))), MaterialIds, new Int32Array([]), new Int32Array([]), [], new Int32Array([]), [], StateSelectFilterHelper_selectedItemsDisplayValues(state.SelectFilters, toString(new SearchFilterKey(8, []))), StateSelectFilterHelper_selectedItemsDisplayValues(state.SelectFilters, toString(new SearchFilterKey(9, []))), StateSelectFilterHelper_selectedItemsIdsAsStrings(state.SelectFilters, toString(new SearchFilterKey(30, []))), StateSelectFilterHelper_selectedItemsIdsAsStrings(state.SelectFilters, toString(new SearchFilterKey(11, []))), StateSelectFilterHelper_selectedItemsDisplayValues(state.SelectFilters, toString(new SearchFilterKey(13, []))), StateSelectFilterHelper_selectedItemsDisplayValues(state.SelectFilters, toString(new SearchFilterKey(14, []))), new Int32Array([]), [], [], "", "None", "None", defaultOf(), "", "", defaultOf(), defaultOf(), state.Page);
}

function hasFilters(state) {
    const request = buildReportRequest(state);
    if (((((((((state.ShowProjectSearch && !(request.ProjectIds.length === 0)) ? true : !(request.MaterialIds.length === 0)) ? true : !(request.OwnerIds.length === 0)) ? true : !(request.SupplierSKUIds.length === 0)) ? true : !(request.AWSSKUIds.length === 0)) ? true : !(request.CartonIds.length === 0)) ? true : !(request.PalletIds.length === 0)) ? true : !(request.ASNReferenceIds.length === 0)) ? true : !(request.ReservationIds.length === 0)) {
        return true;
    }
    else {
        return !(request.WarehouseIds.length === 0);
    }
}

export function convertStateToMultiSearch(typeHeldState) {
    return TypeStateHeldModule_toMultiSearchState(typeHeldState);
}

export function convertStateToTypeAheadSearch(typeHeldState) {
    return TypeStateHeldModule_toTypeAheadSearchState(typeHeldState);
}

export function init(user) {
    let ShowProjectSearch;
    const patternInput = init_1(new SearchFilterKey(1, []), (searchCriteria) => getProjects(user, searchCriteria));
    const patternInput_1 = init_1(new SearchFilterKey(2, []), (searchCriteria_1) => getOwners(user, searchCriteria_1));
    const patternInput_2 = init_1(new SearchFilterKey(3, []), (searchCriteria_2) => getMaterials(user, searchCriteria_2));
    const materialSearchCmd = patternInput_2[1];
    const patternInput_3 = init_1(new SearchFilterKey(11, []), (searchCriteria_3) => getSupplierSKUs(user, searchCriteria_3));
    const patternInput_4 = init_2(toString(new SearchFilterKey(8, [])), 5);
    const patternInput_5 = init_2(toString(new SearchFilterKey(9, [])), 8);
    const patternInput_6 = init_2(toString(new SearchFilterKey(30, [])), 23);
    const patternInput_7 = init_3(toString(new SearchFilterKey(11, [])), LookupResponseModule_ToSelectItems(user.SupplierSKUs), "Choose Supplier ..");
    const patternInput_8 = init_3(toString(new SearchFilterKey(4, [])), LookupResponseModule_ToSelectItems(user.Warehouses), "Choose Warehouse ..");
    const selectFilters = [];
    void (selectFilters.push(StateSelectFilterModule_addMultiSearchFilter(new SearchFilterKey(1, []), patternInput[0])));
    void (selectFilters.push(StateSelectFilterModule_addMultiSearchFilter(new SearchFilterKey(2, []), patternInput_1[0])));
    void (selectFilters.push(StateSelectFilterModule_addMultiSearchFilter(new SearchFilterKey(3, []), patternInput_2[0])));
    void (selectFilters.push(StateSelectFilterModule_addMultiSearchFilter(new SearchFilterKey(11, []), patternInput_3[0])));
    void (selectFilters.push(StateSelectFilterModule_addTypeAheadSearchFilter(new SearchFilterKey(8, []), patternInput_4[0])));
    void (selectFilters.push(StateSelectFilterModule_addTypeAheadSearchFilter(new SearchFilterKey(9, []), patternInput_5[0])));
    void (selectFilters.push(StateSelectFilterModule_addTypeAheadSearchFilter(new SearchFilterKey(30, []), patternInput_6[0])));
    const patternInput_9 = init_4("Export");
    return [(ShowProjectSearch = userHasClaim(user, 16), new State(new Deferred$1(0, []), user, new Deferred$1(0, []), ofArray(selectFilters.slice()), patternInput_9[0], ShowProjectSearch, void 0, patternInput_8[0], patternInput_7[0], 1)), Cmd_batch(ofArray([Cmd_map((Item) => (new Msg(3, [Item])), patternInput[1]), Cmd_map((Item_1) => (new Msg(3, [Item_1])), patternInput_1[1]), Cmd_map((Item_2) => (new Msg(3, [Item_2])), materialSearchCmd), Cmd_map((Item_3) => (new Msg(3, [Item_3])), patternInput_3[1]), Cmd_map((Item_4) => (new Msg(3, [Item_4])), materialSearchCmd), Cmd_map((Item_5) => (new Msg(3, [Item_5])), materialSearchCmd), patternInput_9[1]]))];
}

export function update(msg, state) {
    let input_3, filterKey_1, existingFilter, multiSearchState, searchState, ownerIds_1, pSearchProjectState, matchValue_1, filteredProjects_1, array_4, filteredProjects, newSelectFilters_1;
    const ownerIds = StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(2, [])));
    let searchProjects;
    const filterKey = toString(new SearchFilterKey(1, []));
    searchProjects = ((searchCriteria) => commonSearchProjects(state.SelectFilters, filterKey, ownerIds, state.CurrentUser.Projects, searchCriteria));
    switch (msg.tag) {
        case 9:
            if (hasNextPage(state)) {
                return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.ExportReportState, state.ShowProjectSearch, state.SelectedWarehouse, state.WarehouseSelectState, state.SupplierSelectState, state.Page + 1), singleton((dispatch_1) => {
                    dispatch_1(new Msg(0, [new AsyncMsg$1(0, [])]));
                })];
            }
            else {
                return [state, Cmd_none()];
            }
        case 10:
            if (hasPreviousPage(state)) {
                return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.ExportReportState, state.ShowProjectSearch, state.SelectedWarehouse, state.WarehouseSelectState, state.SupplierSelectState, state.Page - 1), singleton((dispatch_2) => {
                    dispatch_2(new Msg(0, [new AsyncMsg$1(0, [])]));
                })];
            }
            else {
                return [state, Cmd_none()];
            }
        case 0:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2: {
                    let command_2;
                    const input_1 = msg.fields[0].fields[0];
                    command_2 = ((input_1.tag === 1) ? Alert_error(Alert_title("On Hand Details Query", Alert_texts(ofArray(input_1.fields[0].Messages)))) : Cmd_none());
                    return [new State(new Deferred$1(2, [(input_3 = msg.fields[0].fields[0], (input_3.tag === 1) ? Default_pagedData() : input_3.fields[0])]), state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.ExportReportState, state.ShowProjectSearch, state.SelectedWarehouse, state.WarehouseSelectState, state.SupplierSelectState, state.Page), command_2];
                }
                default:
                    if (equals(state.PagedDataDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else {
                        return [new State(new Deferred$1(1, []), state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.ExportReportState, state.ShowProjectSearch, state.SelectedWarehouse, state.WarehouseSelectState, state.SupplierSelectState, state.Page), Cmd_OfAsyncWith_perform((computation) => {
                            startImmediate(computation);
                        }, getOnHandDetails, buildReportRequest(state), (arg) => (new Msg(0, [new AsyncMsg$1(2, [arg])])))];
                    }
            }
        case 1:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2:
                    return [new State(state.PagedDataDeferred, state.CurrentUser, new Deferred$1(2, [void 0]), state.SelectFilters, state.ExportReportState, state.ShowProjectSearch, state.SelectedWarehouse, state.WarehouseSelectState, state.SupplierSelectState, state.Page), Toast_info(Toast_title("Report Creation Status", Toast_text("Report Submitted")))];
                default:
                    if (equals(state.ExportDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else {
                        return [new State(state.PagedDataDeferred, state.CurrentUser, new Deferred$1(1, []), state.SelectFilters, state.ExportReportState, state.ShowProjectSearch, state.SelectedWarehouse, state.WarehouseSelectState, state.SupplierSelectState, state.Page), Cmd_OfAsyncWith_perform((computation_1) => {
                            startImmediate(computation_1);
                        }, exportOnHandDetails, buildReportRequest(state), (arg_2) => (new Msg(1, [new AsyncMsg$1(2, [arg_2])])))];
                    }
            }
        case 3: {
            const filterId = extractName(msg.fields[0]);
            const multiSearchFilter = StateSelectFilterHelper_extractMultiSelectFilter(StateSelectFilterHelper_tryGet(state.SelectFilters, filterId));
            if (multiSearchFilter != null) {
                const patternInput_1 = update_1(msg.fields[0], multiSearchFilter);
                const componentIntent_1 = patternInput_1[2];
                const componentCmd_1 = patternInput_1[1];
                let command_5;
                if (componentIntent_1.tag === 1) {
                    command_5 = Cmd_map((Item_6) => (new Msg(3, [Item_6])), componentCmd_1);
                }
                else {
                    const changedData = componentIntent_1.fields[0];
                    const matchValue = filterId === toString(new SearchFilterKey(2, []));
                    command_5 = (matchValue ? Cmd_batch(ofArray([Cmd_map((Item_5) => (new Msg(3, [Item_5])), componentCmd_1), singleton((dispatch_4) => {
                        dispatch_4(new Msg(4, [changedData]));
                    }), singleton((dispatch_5) => {
                        dispatch_5(new Msg(8, []));
                    })])) : Cmd_batch(ofArray([Cmd_map((Item_4) => (new Msg(3, [Item_4])), componentCmd_1), singleton((dispatch_3) => {
                        dispatch_3(new Msg(4, [changedData]));
                    })])));
                }
                return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, map((x_3) => {
                    if (toString(x_3.Id) === filterId) {
                        return new StateSelectFilter(x_3.Id, new TypeStateHeld(0, [patternInput_1[0]]), x_3.SelectedItems);
                    }
                    else {
                        return x_3;
                    }
                }, state.SelectFilters), state.ExportReportState, state.ShowProjectSearch, state.SelectedWarehouse, state.WarehouseSelectState, state.SupplierSelectState, state.Page), command_5];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 8:
            return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, (filterKey_1 = (new SearchFilterKey(1, [])), (existingFilter = StateSelectFilterHelper_tryGet(state.SelectFilters, toString(filterKey_1)), (existingFilter != null) ? ((multiSearchState = convertStateToMultiSearch(existingFilter.State), (filterKey_1.tag === 1) ? ((searchState = init_1(filterKey_1, searchProjects)[0], (ownerIds_1 = StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(2, []))), (pSearchProjectState = ((matchValue_1 = (ownerIds_1.length > 0), matchValue_1 ? ((filteredProjects_1 = sortBy((x_8) => x_8.DisplayValue, map_1((item_3) => (new DataSourceItem(int32ToString(item_3.Id), item_3.Name, item_3)), (array_4 = state.CurrentUser.Projects.filter((item_1) => contains(item_1.OwnerId, ownerIds_1, {
                Equals: (x_7, y_1) => (x_7 === y_1),
                GetHashCode: numberHash,
            })), array_4.filter((item_2) => !((item_2.Name === "") ? true : (item_2.Name === defaultOf()))))), {
                Compare: comparePrimitives,
            }), new State_3(searchState.Name, searchState.Disabled, searchState.Search, new Deferred$1(2, [new FSharpResult$2(0, [new DataSource(toString(new SearchFilterKey(1, [])), false, filteredProjects_1)])]), searchState.IsModalActive, searchState.SearchCriteria, empty()))) : ((filteredProjects = sortBy((x_5) => x_5.DisplayValue, map_1((item) => (new DataSourceItem(int32ToString(item.Id), item.Name, item)), state.CurrentUser.Projects), {
                Compare: comparePrimitives,
            }), new State_3(searchState.Name, searchState.Disabled, searchState.Search, new Deferred$1(2, [new FSharpResult$2(0, [new DataSource(toString(new SearchFilterKey(1, [])), false, filteredProjects)])]), searchState.IsModalActive, searchState.SearchCriteria, empty()))))), (newSelectFilters_1 = [], (void map((item_5) => {
                void (newSelectFilters_1.push(item_5));
            }, filter_3((item_4) => !equals(item_4.Id, filterKey_1), state.SelectFilters)), (void (newSelectFilters_1.push(StateSelectFilterModule_addMultiSearchFilter(filterKey_1, pSearchProjectState))), ofArray(newSelectFilters_1.slice())))))))) : (() => {
                throw new Error("Match failure");
            })())) : state.SelectFilters)), state.ExportReportState, state.ShowProjectSearch, state.SelectedWarehouse, state.WarehouseSelectState, state.SupplierSelectState, state.Page), Cmd_none()];
        case 4:
            return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, map((x_10) => {
                if (toString(x_10.Id) === msg.fields[0].Name) {
                    return new StateSelectFilter(x_10.Id, x_10.State, msg.fields[0].Items);
                }
                else {
                    return x_10;
                }
            }, state.SelectFilters), state.ExportReportState, state.ShowProjectSearch, state.SelectedWarehouse, state.WarehouseSelectState, state.SupplierSelectState, isEmpty(msg.fields[0].Items) ? state.Page : 1), Cmd_none()];
        case 5: {
            const filterId_1 = extractName_1(msg.fields[0]);
            const typeAheadFilter = StateSelectFilterHelper_extractTypeAheadFilter(StateSelectFilterHelper_tryGet(state.SelectFilters, filterId_1));
            if (typeAheadFilter != null) {
                const patternInput_3 = update_2(msg.fields[0], typeAheadFilter);
                const componentIntent_2 = patternInput_3[2];
                const componentCmd_2 = patternInput_3[1];
                const command_6 = (componentIntent_2.tag === 1) ? Cmd_map((Item_8) => (new Msg(5, [Item_8])), componentCmd_2) : ((componentIntent_2.tag === 2) ? Cmd_map((Item_8) => (new Msg(5, [Item_8])), componentCmd_2) : Cmd_batch(ofArray([Cmd_map((Item_7) => (new Msg(5, [Item_7])), componentCmd_2), singleton((dispatch_6) => {
                    dispatch_6(new Msg(6, [componentIntent_2.fields[0]]));
                })])));
                return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, map((x_11) => {
                    if (toString(x_11.Id) === filterId_1) {
                        return new StateSelectFilter(x_11.Id, new TypeStateHeld(1, [patternInput_3[0]]), x_11.SelectedItems);
                    }
                    else {
                        return x_11;
                    }
                }, state.SelectFilters), state.ExportReportState, state.ShowProjectSearch, state.SelectedWarehouse, state.WarehouseSelectState, state.SupplierSelectState, state.Page), command_6];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 6:
            return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, map((x_13) => {
                if (toString(x_13.Id) === msg.fields[0].SearchDataId) {
                    return new StateSelectFilter(x_13.Id, x_13.State, ofArray(msg.fields[0].Data));
                }
                else {
                    return x_13;
                }
            }, state.SelectFilters), state.ExportReportState, state.ShowProjectSearch, state.SelectedWarehouse, state.WarehouseSelectState, state.SupplierSelectState, isEmpty(ofArray(msg.fields[0].Data)) ? state.Page : 1), Cmd_none()];
        case 7: {
            const patternInput_4 = update_3(msg.fields[0], state.WarehouseSelectState);
            const componentState_3 = patternInput_4[0];
            const componentIntent_3 = patternInput_4[2];
            const componentCmd_3 = patternInput_4[1];
            switch (componentIntent_3.tag) {
                case 0:
                    return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.ExportReportState, state.ShowProjectSearch, componentIntent_3.fields[0], componentState_3, state.SupplierSelectState, state.Page), Cmd_batch(ofArray([Cmd_none(), componentCmd_3]))];
                case 2:
                    return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.ExportReportState, state.ShowProjectSearch, state.SelectedWarehouse, componentState_3, state.SupplierSelectState, state.Page), componentCmd_3];
                default:
                    return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.ExportReportState, state.ShowProjectSearch, void 0, componentState_3, state.SupplierSelectState, state.Page), Cmd_batch(ofArray([Cmd_none(), componentCmd_3]))];
            }
        }
        default: {
            const patternInput = update_4(msg.fields[0], state.ExportReportState);
            const componentState = patternInput[0];
            const componentIntent = patternInput[2];
            switch (componentIntent.tag) {
                case 1:
                    return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, state.SelectFilters, componentState, state.ShowProjectSearch, state.SelectedWarehouse, state.WarehouseSelectState, state.SupplierSelectState, state.Page), Toast_info(Toast_title("Report Creation Status", Toast_text("Report Export Cancelled")))];
                case 2:
                    return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, state.SelectFilters, componentState, state.ShowProjectSearch, state.SelectedWarehouse, state.WarehouseSelectState, state.SupplierSelectState, state.Page), Cmd_none()];
                default:
                    return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, state.SelectFilters, componentState, state.ShowProjectSearch, state.SelectedWarehouse, state.WarehouseSelectState, state.SupplierSelectState, state.Page), singleton((dispatch) => {
                        dispatch(new Msg(1, [new AsyncMsg$1(0, [])]));
                    })];
            }
        }
    }
}

export function renderTop(state, dispatch) {
    let elems_2, elems_1, elems, matchValue, pagedData;
    return createElement("div", createObj(singleton((elems_2 = [createElement("h1", {
        className: join(" ", ["has-text-link", "has-text-weight-semibold"]),
        children: "On Hand Details Query",
    }), createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-flex", "is-align-items-center"])], (elems = [createElement("h1", {
        className: join(" ", ["has-text-link"]),
        children: (matchValue = state.PagedDataDeferred, (matchValue.tag === 1) ? "Searching..." : ((matchValue.tag === 2) ? ((pagedData = matchValue.fields[0], `${pagedData.TotalCount} items found | showing page ${state.Page} of ${pagedData.NumberOfPages}`)) : "")),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))]))));
}

export function renderRow1(state, dispatch) {
    let elems_10;
    return createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_10 = toList(delay(() => {
        let elems;
        return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter) => render_1(convertStateToMultiSearch(filter.State), (arg) => {
            dispatch(new Msg(3, [arg]));
        }), new SearchFilterKey(2, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let elems_1;
            return append(state.ShowProjectSearch ? singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_1 = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter_1) => render_1(convertStateToMultiSearch(filter_1.State), (arg_1) => {
                dispatch(new Msg(3, [arg_1]));
            }), new SearchFilterKey(1, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))) : empty_1(), delay(() => {
                let elems_2;
                return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_2 = [render_2(state.WarehouseSelectState, (arg_2) => {
                    dispatch(new Msg(7, [arg_2]));
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
                    let elems_9, elems_8, elems_4, elems_3, elems_6, elems_5, elems_7;
                    return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow"])], (elems_9 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-right"])], (elems_8 = [createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small"])], ["type", "button"], ["disabled", !hasPreviousPage(state) ? true : !hasFilters(state)], ["onClick", (_arg) => {
                        dispatch(new Msg(10, []));
                    }], (elems_4 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "is-small"])], (elems_3 = [createElement("span", {
                        children: ["navigate_before"],
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("span", {
                        className: join(" ", ["is-sr-only"]),
                        children: "Previous",
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "ml-2"])], ["type", "button"], ["disabled", !hasNextPage(state) ? true : !hasFilters(state)], ["onClick", (_arg_1) => {
                        dispatch(new Msg(9, []));
                    }], (elems_6 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "is-small"])], (elems_5 = [createElement("span", {
                        children: ["navigate_next"],
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("span", {
                        className: join(" ", ["is-sr-only"]),
                        children: "Next",
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))), createElement("div", createObj(singleton((elems_7 = [render_3(state.ExportReportState, (arg_3) => {
                        dispatch(new Msg(2, [arg_3]));
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])]))));
                }));
            }));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])));
}

export function renderRow2(state, dispatch) {
    let elems_5, elems_4, elems_3, elems, elems_1, elems_2;
    return createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_5 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter) => render_1(convertStateToMultiSearch(filter.State), (arg) => {
        dispatch(new Msg(3, [arg]));
    }), new SearchFilterKey(11, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_1 = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter_1) => render_4(convertStateToTypeAheadSearch(filter_1.State), (arg_1) => {
        dispatch(new Msg(5, [arg_1]));
    }), new SearchFilterKey(8, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_2 = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter_2) => render_4(convertStateToTypeAheadSearch(filter_2.State), (arg_2) => {
        dispatch(new Msg(5, [arg_2]));
    }), new SearchFilterKey(9, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])));
}

export function renderRow3(state, dispatch) {
    let elems_3, elems_2, elems_1, elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-one-third"])], (elems = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter) => render_1(convertStateToMultiSearch(filter.State), (arg) => {
        dispatch(new Msg(3, [arg]));
    }), new SearchFilterKey(3, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
}

export function renderReport(state, dispatch) {
    let elems_5;
    return createElement("div", createObj(ofArray([["className", join(" ", ["box"])], (elems_5 = toList(delay(() => {
        let elems_4, elems_3, elems, children, elems_2;
        const matchValue = state.PagedDataDeferred;
        switch (matchValue.tag) {
            case 1:
                return singleton_1(render_5());
            case 2: {
                const pagedData = matchValue.fields[0];
                if (pagedData.TotalCount === 0) {
                    return singleton_1(createElement("div", {
                        className: join(" ", ["has-text-centered"]),
                        children: "No items were found that meet the search criteria",
                    }));
                }
                else {
                    const tableHeader = (header) => createElement("th", {
                        className: join(" ", ["px-3", "py-2", "has-text-link"]),
                        children: header,
                    });
                    const tableData = (text) => createElement("td", {
                        className: join(" ", ["px-3", "py-2"]),
                        children: text,
                    });
                    return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["table-container"])], (elems_4 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-fullwidth"])], (elems_3 = [createElement("thead", createObj(singleton((elems = [(children = ofArray([tableHeader("Warehouse"), tableHeader("Supplier ID"), tableHeader("Supplier"), tableHeader("AWS SKU"), tableHeader("Supplier SKU"), tableHeader("SKU Description"), tableHeader("UOM"), tableHeader("Location"), tableHeader("Carton ID"), tableHeader("Pallet ID"), tableHeader("Qty"), tableHeader("Qty Allocated"), tableHeader("Qty Picked"), tableHeader("Qty Hold"), tableHeader("Qty Available"), tableHeader("Receipt"), tableHeader("Date Received"), tableHeader("ASN Reference"), tableHeader("COO"), tableHeader("ODM ID"), tableHeader("Reservation ID"), tableHeader("Date Reserved")]), createElement("tr", {
                        children: Interop_reactApi.Children.toArray(Array.from(children)),
                    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])))), createElement("tbody", createObj(singleton((elems_2 = toList(delay(() => map_2((item) => {
                        let elems_1;
                        return createElement("tr", createObj(singleton((elems_1 = [tableData(item.WarehouseName), tableData(item.SupplierId), tableData(item.Supplier), tableData(item.AwsSku), tableData(item.SupplierSku), tableData(item.SkuDescription), tableData(item.Uom), tableData(item.Location), tableData(item.CartonId), tableData(item.PalletId), tableData(formatDecimal(item.Quantity)), tableData(formatDecimal(item.QuantityAllocated)), tableData(formatDecimal(item.QuantityPicked)), tableData(formatDecimal(item.QuantityOnHold)), tableData(formatDecimal(item.QuantityAvailable)), tableData(item.Receipt), tableData(formatDateTime(item.DateReceived)), tableData(item.AsnReference), tableData(item.Coo), tableData(item.OdmId), tableData(item.ReservationId), tableData(formatDateTime(item.DateReserved))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]))));
                    }, pagedData.Data))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))));
                }
            }
            default:
                return singleton_1(createElement("div", {
                    className: join(" ", ["has-text-centered"]),
                    children: "Select filters to run the query",
                }));
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])));
}

export function renderRunReport(state, dispatch) {
    let elems_4, elems_3, elems_2, elems_1, elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_4 = [createElement("div", {
        className: join(" ", ["column"]),
    }), createElement("div", {
        className: join(" ", ["column"]),
    }), createElement("div", {
        className: join(" ", ["column"]),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_2 = [createElement("div", {
        className: join(" ", ["column"]),
    }), createElement("div", {
        className: join(" ", ["column"]),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_1 = [createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-primary", "is-small"])], ["type", "button"], ["onClick", (_arg) => {
        dispatch(new Msg(0, [new AsyncMsg$1(0, [])]));
    }], (elems = [createElement("div", {
        children: "Run Report",
    }), createElement("br", {})], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
}

export function showLoadingSpinner(state) {
    if (equals(state.PagedDataDeferred, new Deferred$1(1, []))) {
        return true;
    }
    else {
        return equals(state.ExportDeferred, new Deferred$1(1, []));
    }
}

export function render(state, dispatch) {
    let elems_3, elems_1, elems, elems_2;
    if (showLoadingSpinner(state)) {
        return render_5();
    }
    else {
        return createElement("div", createObj(singleton((elems_3 = [createElement("div", createObj(ofArray([["className", "columns"], (elems_1 = [createElement("div", {
            className: join(" ", ["column"]),
        }), createElement("div", {
            className: join(" ", ["column"]),
        }), createElement("div", createObj(ofArray([["className", join(" ", ["column", "has-text-right"])], (elems = [createElement("a", {
            className: join(" ", ["has-link"]),
            href: "#/scheduled-reports",
            children: "Scheduled Reports",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", "box"], (elems_2 = [renderTop(state, dispatch), renderRow1(state, dispatch), renderRow2(state, dispatch), renderRow3(state, dispatch), renderRunReport(state, dispatch)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), renderReport(state, dispatch)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))]))));
    }
}

