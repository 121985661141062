import { toString, Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { ReportRequest, ApiMessageResponse_$reflection, UserResponse_$reflection, PagedDataResponse$1_$reflection, OutboundSummaryReportResponse_$reflection } from "../../FootPrint.Portal.DTOs/DTOs.fs.js";
import { AsyncMsg$1, Deferred$1, Deferred_exists, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { union_type, record_type, class_type, enum_type, int32_type, option_type, bool_type, list_type, unit_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { StateSelectFilterHelper_renderSearch, StateSelectFilterHelper_extractTypeAheadFilter, StateSelectFilter, TypeStateHeld, StateSelectFilterHelper_tryGet, StateSelectFilterHelper_extractMultiSelectFilter, StateSelectFilterModule_addTypeAheadSearchFilter, StateSelectFilterModule_addMultiSearchFilter, TypeStateHeldModule_toTypeAheadSearchState, TypeStateHeldModule_toMultiSearchState, StateSelectFilterHelper_selectedItemsDisplayValues, StateSelectFilterHelper_selectedItemsIds, StateSelectFilter_$reflection } from "../Utilities/StateSelectFilterHelper.fs.js";
import { DataSource, DataSourceItem, Default_pagedData, SearchFilterKey, DataSource_$reflection, DataSourceItem_$reflection } from "../Models.fs.js";
import { render as render_3, update as update_3, init as init_3, MultiDateRangeTypeModule_toInt, Msg_$reflection as Msg_$reflection_3, State_$reflection as State_$reflection_1 } from "../Components/MultiDateSearch.fs.js";
import { render as render_4, update as update_4, OrderTypeInboundOrOutbound, init as init_4, Msg_$reflection as Msg_$reflection_4, State_$reflection as State_$reflection_2 } from "../Components/OrderTypeStatus.fs.js";
import { render as render_2, update as update_5, init as init_5, Msg_$reflection as Msg_$reflection_5, State_$reflection as State_$reflection_3 } from "../Components/ExportReportDialog.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { render as render_1, State as State_3, update as update_1, extractName, init as init_1, SelectedItemsChangedData_$reflection, Msg_$reflection as Msg_$reflection_1 } from "../Components/MultiSelectSearch.fs.js";
import { render as render_5, update as update_2, extractName as extractName_1, init as init_2, Msg_$reflection as Msg_$reflection_2 } from "../Components/TypeAheadSearch.fs.js";
import { value as value_48 } from "../fable_modules/fable-library.4.10.0/Option.js";
import { Int32_fromString } from "../../FootPrint.Portal.DTOs/PrimitiveTypes.fs.js";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { createObj, comparePrimitives, numberHash, int32ToString, equals, defaultOf } from "../fable_modules/fable-library.4.10.0/Util.js";
import { getExternalOrderNos, getWarehouses, getAccounts, getOwners, getProjects } from "../API/DataSourceAPI.fs.js";
import { isEmpty, filter as filter_4, empty, map, singleton, ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { userHasClaim } from "../Components/ClaimHelpers.fs.js";
import { Cmd_none, Cmd_map, Cmd_batch } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { commonSearchProjects } from "../Utilities/UpdateUtilities.fs.js";
import { Toast_text, Toast_title, Toast_info, Alert_texts, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.1.0/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { exportOutboundSummary, getOutboundSummary } from "../API/ReportAPI.fs.js";
import { contains, map as map_1, sortBy } from "../fable_modules/fable-library.4.10.0/Array.js";
import { createElement } from "react";
import { map as map_2, empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { render as render_6 } from "../Components/Loader.fs.js";
import { formatDecimal, formatDateTime, formatInt } from "../Domain/Formatter.fs.js";

export class State extends Record {
    constructor(PagedDataDeferred, CurrentUser, ExportDeferred, SelectFilters, ShowProjectSearch, SelectedOrderStatus, SelectedOrderType, DateFilter, DateRangeType, DateStart, DateEnd, OrderAddDateRange, OrderTypeStatus, ExportReportState, Page) {
        super();
        this.PagedDataDeferred = PagedDataDeferred;
        this.CurrentUser = CurrentUser;
        this.ExportDeferred = ExportDeferred;
        this.SelectFilters = SelectFilters;
        this.ShowProjectSearch = ShowProjectSearch;
        this.SelectedOrderStatus = SelectedOrderStatus;
        this.SelectedOrderType = SelectedOrderType;
        this.DateFilter = DateFilter;
        this.DateRangeType = (DateRangeType | 0);
        this.DateStart = DateStart;
        this.DateEnd = DateEnd;
        this.OrderAddDateRange = OrderAddDateRange;
        this.OrderTypeStatus = OrderTypeStatus;
        this.ExportReportState = ExportReportState;
        this.Page = (Page | 0);
    }
}

export function State_$reflection() {
    return record_type("FootPrint.Portal.UI.Pages.OutboundSummaryQuery.State", [], State, () => [["PagedDataDeferred", Deferred$1_$reflection(PagedDataResponse$1_$reflection(OutboundSummaryReportResponse_$reflection()))], ["CurrentUser", UserResponse_$reflection()], ["ExportDeferred", Deferred$1_$reflection(unit_type)], ["SelectFilters", list_type(StateSelectFilter_$reflection())], ["ShowProjectSearch", bool_type], ["SelectedOrderStatus", option_type(DataSourceItem_$reflection())], ["SelectedOrderType", option_type(DataSourceItem_$reflection())], ["DateFilter", State_$reflection_1()], ["DateRangeType", enum_type("FootPrint.Portal.UI.MultipleDateFilter.MultipleDateFilter.MultiDateRangeType", int32_type, [["Choose", 0], ["Created", 1], ["Processed", 2], ["Completed", 3], ["Cancelled", 4]])], ["DateStart", option_type(class_type("System.DateTime"))], ["DateEnd", option_type(class_type("System.DateTime"))], ["OrderAddDateRange", State_$reflection_1()], ["OrderTypeStatus", State_$reflection_2()], ["ExportReportState", State_$reflection_3()], ["Page", int32_type]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["QueryAsync", "ExportAsync", "MultiSelectSearchMsg", "TypeAheadSearchMsg", "SetTypeAheadSearchItems", "SetMultiSearchItems", "DateFilterMsg", "OrderTypeStatusMsg", "SetOrderStatus", "SetOrderType", "ExportReportMsg", "RebuildProjectFilters", "SetNextPage", "SetPreviousPage"];
    }
}

export function Msg_$reflection() {
    return union_type("FootPrint.Portal.UI.Pages.OutboundSummaryQuery.Msg", [], Msg, () => [[["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedDataResponse$1_$reflection(OutboundSummaryReportResponse_$reflection()), ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", PagedDataResponse$1_$reflection(OutboundSummaryReportResponse_$reflection())]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["Item", Msg_$reflection_1()]], [["Item", Msg_$reflection_2()]], [["Item", DataSource_$reflection()]], [["Item", SelectedItemsChangedData_$reflection()]], [["Item", Msg_$reflection_3()]], [["Item", Msg_$reflection_4()]], [["Item", option_type(DataSourceItem_$reflection())]], [["Item", option_type(DataSourceItem_$reflection())]], [["Item", Msg_$reflection_5()]], [], [], []]);
}

function hasPreviousPage(state) {
    return state.Page > 1;
}

function hasNextPage(state) {
    return Deferred_exists((pagedData) => ((state.Page * pagedData.PageSize) < pagedData.TotalCount), state.PagedDataDeferred);
}

function buildReportRequest(state) {
    let matchValue_4, matchValue_5;
    let Description;
    const matchValue = state.ExportReportState.ReportDescription;
    Description = ((matchValue != null) ? matchValue : "");
    let OrderStatus;
    const matchValue_1 = state.SelectedOrderStatus;
    OrderStatus = ((matchValue_1 != null) ? (new Int32Array([value_48(Int32_fromString(matchValue_1.Id))])) : (new Int32Array(0)));
    let OrderType;
    const matchValue_2 = state.SelectedOrderType;
    OrderType = ((matchValue_2 != null) ? (new Int32Array([value_48(Int32_fromString(matchValue_2.Id))])) : (new Int32Array(0)));
    return new ReportRequest("OutboundSummary.xlsx", Description, StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(1, []))), StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(2, []))), StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(0, []))), StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(4, []))), new Int32Array(0), OrderStatus, OrderType, [], new Int32Array([]), [], [], [], [], [], [], [], StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(15, []))), StateSelectFilterHelper_selectedItemsDisplayValues(state.SelectFilters, toString(new SearchFilterKey(16, []))), [], join(",", StateSelectFilterHelper_selectedItemsDisplayValues(state.SelectFilters, toString(new SearchFilterKey(18, [])))), "None", "None", (state.DateRangeType === 0) ? defaultOf() : MultiDateRangeTypeModule_toInt(state.DateRangeType), "", "", (matchValue_4 = state.DateStart, (matchValue_4 != null) ? matchValue_4 : defaultOf()), (matchValue_5 = state.DateEnd, (matchValue_5 != null) ? matchValue_5 : defaultOf()), state.Page);
}

function hasFilters(state) {
    const request = buildReportRequest(state);
    if (((((((((state.ShowProjectSearch && !(request.ProjectIds.length === 0)) ? true : !(request.AccountIds.length === 0)) ? true : !(request.OwnerIds.length === 0)) ? true : !(request.WarehouseIds.length === 0)) ? true : !(request.WMSOrderIds.length === 0)) ? true : !(request.ExternalOrderIds.length === 0)) ? true : !(request.TrackingIds === "")) ? true : !(request.OrderStatus.length === 0)) ? true : !(request.OrderType.length === 0)) {
        return true;
    }
    else {
        return !equals(request.DateType, defaultOf());
    }
}

export function convertStateToMultiSearch(typeHeldState) {
    return TypeStateHeldModule_toMultiSearchState(typeHeldState);
}

export function convertStateToTypeAheadSearch(typeHeldState) {
    return TypeStateHeldModule_toTypeAheadSearchState(typeHeldState);
}

export function init(user) {
    const patternInput = init_1(new SearchFilterKey(1, []), (searchCriteria) => getProjects(user, searchCriteria));
    const patternInput_1 = init_1(new SearchFilterKey(2, []), (searchCriteria_1) => getOwners(user, searchCriteria_1));
    const patternInput_2 = init_1(new SearchFilterKey(0, []), (searchCriteria_2) => getAccounts(user, searchCriteria_2));
    const patternInput_3 = init_1(new SearchFilterKey(4, []), (searchCriteria_3) => getWarehouses(user, searchCriteria_3));
    const patternInput_4 = init_2(toString(new SearchFilterKey(15, [])), 21);
    const patternInput_5 = init_1(new SearchFilterKey(16, []), (searchCriteria_4) => getExternalOrderNos(user, searchCriteria_4));
    const patternInput_6 = init_2(toString(new SearchFilterKey(18, [])), 10);
    const patternInput_7 = init_3("Date Range", true);
    const dateFilterState = patternInput_7[0];
    const patternInput_8 = init_4(new OrderTypeInboundOrOutbound(1, []));
    const patternInput_9 = init_3("Order Add Date", false);
    const selectFilters = [];
    void (selectFilters.push(StateSelectFilterModule_addMultiSearchFilter(new SearchFilterKey(1, []), patternInput[0])));
    void (selectFilters.push(StateSelectFilterModule_addMultiSearchFilter(new SearchFilterKey(2, []), patternInput_1[0])));
    void (selectFilters.push(StateSelectFilterModule_addMultiSearchFilter(new SearchFilterKey(0, []), patternInput_2[0])));
    void (selectFilters.push(StateSelectFilterModule_addMultiSearchFilter(new SearchFilterKey(4, []), patternInput_3[0])));
    void (selectFilters.push(StateSelectFilterModule_addMultiSearchFilter(new SearchFilterKey(16, []), patternInput_5[0])));
    void (selectFilters.push(StateSelectFilterModule_addTypeAheadSearchFilter(new SearchFilterKey(15, []), patternInput_4[0])));
    void (selectFilters.push(StateSelectFilterModule_addTypeAheadSearchFilter(new SearchFilterKey(18, []), patternInput_6[0])));
    const patternInput_10 = init_5("Export");
    return [new State(new Deferred$1(0, []), user, new Deferred$1(0, []), ofArray(selectFilters.slice()), userHasClaim(user, 16), void 0, void 0, dateFilterState, dateFilterState.DateRangeTypeSelection, dateFilterState.DateStart, dateFilterState.DateEnd, patternInput_9[0], patternInput_8[0], patternInput_10[0], 1), Cmd_batch(ofArray([Cmd_map((Item) => (new Msg(2, [Item])), patternInput[1]), Cmd_map((Item_1) => (new Msg(2, [Item_1])), patternInput_1[1]), Cmd_map((Item_2) => (new Msg(2, [Item_2])), patternInput_2[1]), Cmd_map((Item_3) => (new Msg(2, [Item_3])), patternInput_3[1]), Cmd_map((Item_4) => (new Msg(6, [Item_4])), patternInput_7[1]), Cmd_map((Item_5) => (new Msg(7, [Item_5])), patternInput_8[1]), Cmd_map((Item_6) => (new Msg(2, [Item_6])), patternInput_4[1]), Cmd_map((Item_7) => (new Msg(2, [Item_7])), patternInput_5[1]), patternInput_10[1]]))];
}

export function update(msg, state) {
    let input_3, filterKey_1, existingFilter, multiSearchState, searchState, ownerIds_1, pSearchProjectState, matchValue_1, filteredProjects_1, array_4, filteredProjects, newSelectFilters_1;
    const ownerIds = StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(2, [])));
    let searchProjects;
    const filterKey = toString(new SearchFilterKey(1, []));
    searchProjects = ((searchCriteria) => commonSearchProjects(state.SelectFilters, filterKey, ownerIds, state.CurrentUser.Projects, searchCriteria));
    switch (msg.tag) {
        case 13:
            if (hasPreviousPage(state)) {
                return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderAddDateRange, state.OrderTypeStatus, state.ExportReportState, state.Page - 1), singleton((dispatch_1) => {
                    dispatch_1(new Msg(0, [new AsyncMsg$1(0, [])]));
                })];
            }
            else {
                return [state, Cmd_none()];
            }
        case 0:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2: {
                    let command_1;
                    const input_1 = msg.fields[0].fields[0];
                    command_1 = ((input_1.tag === 1) ? Alert_error(Alert_title("Outbound Summary Query", Alert_texts(ofArray(input_1.fields[0].Messages)))) : Cmd_none());
                    return [new State(new Deferred$1(2, [(input_3 = msg.fields[0].fields[0], (input_3.tag === 1) ? Default_pagedData() : input_3.fields[0])]), state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderAddDateRange, state.OrderTypeStatus, state.ExportReportState, state.Page), command_1];
                }
                default:
                    if (equals(state.PagedDataDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else {
                        return [new State(new Deferred$1(1, []), state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderAddDateRange, state.OrderTypeStatus, state.ExportReportState, state.Page), Cmd_OfAsyncWith_perform((computation) => {
                            startImmediate(computation);
                        }, getOutboundSummary, buildReportRequest(state), (arg) => (new Msg(0, [new AsyncMsg$1(2, [arg])])))];
                    }
            }
        case 1:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2:
                    return [new State(state.PagedDataDeferred, state.CurrentUser, new Deferred$1(2, [void 0]), state.SelectFilters, state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderAddDateRange, state.OrderTypeStatus, state.ExportReportState, state.Page), Toast_info(Toast_title("Report Creation Status", Toast_text("Report Submitted")))];
                default:
                    if (equals(state.ExportDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else {
                        return [new State(state.PagedDataDeferred, state.CurrentUser, new Deferred$1(1, []), state.SelectFilters, state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderAddDateRange, state.OrderTypeStatus, state.ExportReportState, state.Page), Cmd_OfAsyncWith_perform((computation_1) => {
                            startImmediate(computation_1);
                        }, exportOutboundSummary, buildReportRequest(state), (arg_2) => (new Msg(1, [new AsyncMsg$1(2, [arg_2])])))];
                    }
            }
        case 2: {
            const filterId = extractName(msg.fields[0]);
            const multiSearchFilter = StateSelectFilterHelper_extractMultiSelectFilter(StateSelectFilterHelper_tryGet(state.SelectFilters, filterId));
            if (multiSearchFilter != null) {
                const patternInput = update_1(msg.fields[0], multiSearchFilter);
                const componentIntent = patternInput[2];
                const componentCmd = patternInput[1];
                let command_4;
                if (componentIntent.tag === 1) {
                    command_4 = Cmd_map((Item_6) => (new Msg(2, [Item_6])), componentCmd);
                }
                else {
                    const changedData = componentIntent.fields[0];
                    const matchValue = filterId === toString(new SearchFilterKey(2, []));
                    command_4 = (matchValue ? Cmd_batch(ofArray([Cmd_map((Item_5) => (new Msg(2, [Item_5])), componentCmd), singleton((dispatch_3) => {
                        dispatch_3(new Msg(5, [changedData]));
                    }), singleton((dispatch_4) => {
                        dispatch_4(new Msg(11, []));
                    })])) : Cmd_batch(ofArray([Cmd_map((Item_4) => (new Msg(2, [Item_4])), componentCmd), singleton((dispatch_2) => {
                        dispatch_2(new Msg(5, [changedData]));
                    })])));
                }
                return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, map((x_3) => {
                    if (toString(x_3.Id) === filterId) {
                        return new StateSelectFilter(x_3.Id, new TypeStateHeld(0, [patternInput[0]]), x_3.SelectedItems);
                    }
                    else {
                        return x_3;
                    }
                }, state.SelectFilters), state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderAddDateRange, state.OrderTypeStatus, state.ExportReportState, state.Page), command_4];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 11:
            return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, (filterKey_1 = (new SearchFilterKey(1, [])), (existingFilter = StateSelectFilterHelper_tryGet(state.SelectFilters, toString(filterKey_1)), (existingFilter != null) ? ((multiSearchState = convertStateToMultiSearch(existingFilter.State), (filterKey_1.tag === 1) ? ((searchState = init_1(filterKey_1, searchProjects)[0], (ownerIds_1 = StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(2, []))), (pSearchProjectState = ((matchValue_1 = (ownerIds_1.length > 0), matchValue_1 ? ((filteredProjects_1 = sortBy((x_8) => x_8.DisplayValue, map_1((item_3) => (new DataSourceItem(int32ToString(item_3.Id), item_3.Name, item_3)), (array_4 = state.CurrentUser.Projects.filter((item_1) => contains(item_1.OwnerId, ownerIds_1, {
                Equals: (x_7, y_1) => (x_7 === y_1),
                GetHashCode: numberHash,
            })), array_4.filter((item_2) => !((item_2.Name === "") ? true : (item_2.Name === defaultOf()))))), {
                Compare: comparePrimitives,
            }), new State_3(searchState.Name, searchState.Disabled, searchState.Search, new Deferred$1(2, [new FSharpResult$2(0, [new DataSource(toString(new SearchFilterKey(1, [])), false, filteredProjects_1)])]), searchState.IsModalActive, searchState.SearchCriteria, empty()))) : ((filteredProjects = sortBy((x_5) => x_5.DisplayValue, map_1((item) => (new DataSourceItem(int32ToString(item.Id), item.Name, item)), state.CurrentUser.Projects), {
                Compare: comparePrimitives,
            }), new State_3(searchState.Name, searchState.Disabled, searchState.Search, new Deferred$1(2, [new FSharpResult$2(0, [new DataSource(toString(new SearchFilterKey(1, [])), false, filteredProjects)])]), searchState.IsModalActive, searchState.SearchCriteria, empty()))))), (newSelectFilters_1 = [], (void map((item_5) => {
                void (newSelectFilters_1.push(item_5));
            }, filter_4((item_4) => !equals(item_4.Id, filterKey_1), state.SelectFilters)), (void (newSelectFilters_1.push(StateSelectFilterModule_addMultiSearchFilter(filterKey_1, pSearchProjectState))), ofArray(newSelectFilters_1.slice())))))))) : (() => {
                throw new Error("Match failure");
            })())) : state.SelectFilters)), state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderAddDateRange, state.OrderTypeStatus, state.ExportReportState, state.Page), Cmd_none()];
        case 5:
            return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, map((x_10) => {
                if (toString(x_10.Id) === msg.fields[0].Name) {
                    return new StateSelectFilter(x_10.Id, x_10.State, msg.fields[0].Items);
                }
                else {
                    return x_10;
                }
            }, state.SelectFilters), state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderAddDateRange, state.OrderTypeStatus, state.ExportReportState, isEmpty(msg.fields[0].Items) ? state.Page : 1), Cmd_none()];
        case 3: {
            const filterId_1 = extractName_1(msg.fields[0]);
            const typeAheadFilter = StateSelectFilterHelper_extractTypeAheadFilter(StateSelectFilterHelper_tryGet(state.SelectFilters, filterId_1));
            if (typeAheadFilter != null) {
                const patternInput_2 = update_2(msg.fields[0], typeAheadFilter);
                const componentIntent_1 = patternInput_2[2];
                const componentCmd_1 = patternInput_2[1];
                const command_5 = (componentIntent_1.tag === 1) ? Cmd_map((Item_8) => (new Msg(3, [Item_8])), componentCmd_1) : ((componentIntent_1.tag === 2) ? Cmd_map((Item_8) => (new Msg(3, [Item_8])), componentCmd_1) : Cmd_batch(ofArray([Cmd_map((Item_7) => (new Msg(3, [Item_7])), componentCmd_1), singleton((dispatch_5) => {
                    dispatch_5(new Msg(4, [componentIntent_1.fields[0]]));
                })])));
                return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, map((x_11) => {
                    if (toString(x_11.Id) === filterId_1) {
                        return new StateSelectFilter(x_11.Id, new TypeStateHeld(1, [patternInput_2[0]]), x_11.SelectedItems);
                    }
                    else {
                        return x_11;
                    }
                }, state.SelectFilters), state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderAddDateRange, state.OrderTypeStatus, state.ExportReportState, state.Page), command_5];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 4:
            return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, map((x_13) => {
                if (toString(x_13.Id) === msg.fields[0].SearchDataId) {
                    return new StateSelectFilter(x_13.Id, x_13.State, ofArray(msg.fields[0].Data));
                }
                else {
                    return x_13;
                }
            }, state.SelectFilters), state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderAddDateRange, state.OrderTypeStatus, state.ExportReportState, isEmpty(ofArray(msg.fields[0].Data)) ? state.Page : 1), Cmd_none()];
        case 6: {
            const patternInput_3 = update_3(msg.fields[0], state.DateFilter);
            const componentState_2 = patternInput_3[0];
            const componentIntent_2 = patternInput_3[2];
            if (componentIntent_2.tag === 1) {
                return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, componentState_2, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderAddDateRange, state.OrderTypeStatus, state.ExportReportState, state.Page), Cmd_none()];
            }
            else {
                const data_1 = componentIntent_2.fields[0];
                return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, componentState_2, data_1.DateRangeType, data_1.DateStart, data_1.DateEnd, state.OrderAddDateRange, state.OrderTypeStatus, state.ExportReportState, state.Page), Cmd_none()];
            }
        }
        case 7: {
            const patternInput_4 = update_4(msg.fields[0], state.OrderTypeStatus);
            const componentIntent_3 = patternInput_4[2];
            const componentCmd_3 = patternInput_4[1];
            return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderAddDateRange, patternInput_4[0], state.ExportReportState, state.Page), (componentIntent_3.tag === 1) ? Cmd_batch(ofArray([Cmd_map((Item_10) => (new Msg(7, [Item_10])), componentCmd_3), singleton((dispatch_7) => {
                dispatch_7(new Msg(9, [componentIntent_3.fields[0]]));
            })])) : ((componentIntent_3.tag === 2) ? Cmd_map((Item_11) => (new Msg(7, [Item_11])), componentCmd_3) : Cmd_batch(ofArray([Cmd_map((Item_9) => (new Msg(7, [Item_9])), componentCmd_3), singleton((dispatch_6) => {
                dispatch_6(new Msg(8, [componentIntent_3.fields[0]]));
            })])))];
        }
        case 8:
            return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.ShowProjectSearch, msg.fields[0], state.SelectedOrderType, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderAddDateRange, state.OrderTypeStatus, state.ExportReportState, 1), Cmd_none()];
        case 9:
            return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.ShowProjectSearch, state.SelectedOrderStatus, msg.fields[0], state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderAddDateRange, state.OrderTypeStatus, state.ExportReportState, 1), Cmd_none()];
        case 10: {
            const patternInput_5 = update_5(msg.fields[0], state.ExportReportState);
            const componentState_4 = patternInput_5[0];
            const componentIntent_4 = patternInput_5[2];
            switch (componentIntent_4.tag) {
                case 1:
                    return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderAddDateRange, state.OrderTypeStatus, componentState_4, state.Page), Toast_info(Toast_title("Report Creation Status", Toast_text("Report Export Cancelled")))];
                case 2:
                    return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderAddDateRange, state.OrderTypeStatus, componentState_4, state.Page), Cmd_none()];
                default:
                    return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderAddDateRange, state.OrderTypeStatus, componentState_4, state.Page), singleton((dispatch_8) => {
                        dispatch_8(new Msg(1, [new AsyncMsg$1(0, [])]));
                    })];
            }
        }
        default:
            if (hasNextPage(state)) {
                return [new State(state.PagedDataDeferred, state.CurrentUser, state.ExportDeferred, state.SelectFilters, state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderAddDateRange, state.OrderTypeStatus, state.ExportReportState, state.Page + 1), singleton((dispatch) => {
                    dispatch(new Msg(0, [new AsyncMsg$1(0, [])]));
                })];
            }
            else {
                return [state, Cmd_none()];
            }
    }
}

export function renderRow1(state, dispatch) {
    let elems_14, elems_13, elems_12, elems_11;
    return createElement("div", createObj(singleton((elems_14 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_13 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_12 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_11 = toList(delay(() => {
        let elems;
        return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter) => render_1(convertStateToMultiSearch(filter.State), (arg) => {
            dispatch(new Msg(2, [arg]));
        }), new SearchFilterKey(2, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let elems_1;
            return append(state.ShowProjectSearch ? singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_1 = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter_1) => render_1(convertStateToMultiSearch(filter_1.State), (arg_1) => {
                dispatch(new Msg(2, [arg_1]));
            }), new SearchFilterKey(1, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))) : empty_1(), delay(() => {
                let elems_2;
                return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_2 = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter_2) => render_1(convertStateToMultiSearch(filter_2.State), (arg_2) => {
                    dispatch(new Msg(2, [arg_2]));
                }), new SearchFilterKey(0, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
                    let elems_3;
                    return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_3 = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter_3) => render_1(convertStateToMultiSearch(filter_3.State), (arg_3) => {
                        dispatch(new Msg(2, [arg_3]));
                    }), new SearchFilterKey(4, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))), delay(() => {
                        let elems_10, elems_9, elems_5, elems_4, elems_7, elems_6, elems_8;
                        return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow"])], (elems_10 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-right"])], (elems_9 = [createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small"])], ["type", "button"], ["disabled", !hasPreviousPage(state) ? true : !hasFilters(state)], ["onClick", (_arg) => {
                            dispatch(new Msg(13, []));
                        }], (elems_5 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "is-small"])], (elems_4 = [createElement("i", {
                            children: ["navigate_before"],
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("span", {
                            className: join(" ", ["is-sr-only"]),
                            children: "Previous",
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "ml-2"])], ["type", "button"], ["disabled", !hasNextPage(state) ? true : !hasFilters(state)], ["onClick", (_arg_1) => {
                            dispatch(new Msg(12, []));
                        }], (elems_7 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "is-small"])], (elems_6 = [createElement("i", {
                            children: ["navigate_next"],
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))), createElement("span", {
                            className: join(" ", ["is-sr-only"]),
                            children: "Next",
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))), createElement("div", createObj(singleton((elems_8 = [render_2(state.ExportReportState, (arg_4) => {
                            dispatch(new Msg(10, [arg_4]));
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])]))));
                    }));
                }));
            }));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))]))));
}

export function renderRow2(state, dispatch) {
    let elems_2, elems, elems_1;
    return createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_2 = [createElement("span", createObj(ofArray([["className", join(" ", ["column"])], (elems = [render_3(state.DateFilter, (arg) => {
        dispatch(new Msg(6, [arg]));
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("span", createObj(ofArray([["className", join(" ", ["column"])], (elems_1 = [render_4(state.OrderTypeStatus, (arg_1) => {
        dispatch(new Msg(7, [arg_1]));
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function renderRow3(state, dispatch) {
    let elems_2, elems, elems_1;
    return createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter) => render_1(convertStateToMultiSearch(filter.State), (arg) => {
        dispatch(new Msg(2, [arg]));
    }), new SearchFilterKey(16, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_1 = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter_1) => render_5(convertStateToTypeAheadSearch(filter_1.State), (arg_1) => {
        dispatch(new Msg(3, [arg_1]));
    }), new SearchFilterKey(15, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function renderRow4(state, dispatch) {
    let elems_1, elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-one-third"])], (elems = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter) => render_5(convertStateToTypeAheadSearch(filter.State), (arg) => {
        dispatch(new Msg(3, [arg]));
    }), new SearchFilterKey(18, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function renderRow5(state, dispatch) {
    return createElement("div", {
        className: join(" ", ["columns"]),
        children: Interop_reactApi.Children.toArray([]),
    });
}

export function renderRowReport(state, dispatch) {
    let elems_5;
    return createElement("div", createObj(ofArray([["className", join(" ", ["box"])], (elems_5 = toList(delay(() => {
        let elems_4, elems_3, elems, children, elems_2;
        const matchValue = state.PagedDataDeferred;
        switch (matchValue.tag) {
            case 1:
                return singleton_1(render_6());
            case 2: {
                const pagedData = matchValue.fields[0];
                if (pagedData.TotalCount === 0) {
                    return singleton_1(createElement("div", {
                        className: join(" ", ["has-text-centered"]),
                        children: "No items were found that meet the search criteria",
                    }));
                }
                else {
                    const tableHeader = (header) => createElement("th", {
                        className: join(" ", ["px-3", "py-2", "has-text-link"]),
                        children: header,
                    });
                    const tableData = (text) => createElement("td", {
                        className: join(" ", ["px-3", "py-2"]),
                        children: text,
                    });
                    return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["table-container"])], (elems_4 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-fullwidth"])], (elems_3 = [createElement("thead", createObj(singleton((elems = [(children = ofArray([tableHeader("WMS Order #"), tableHeader("External Order #"), tableHeader("Tracking ID/Pro #"), tableHeader("Priority"), tableHeader("Priority Date"), tableHeader("ODM ID"), tableHeader("ODM Name"), tableHeader("Supplier ID"), tableHeader("Supplier Name"), tableHeader("Order Add Date"), tableHeader("Allocation Date"), tableHeader("Staged Date"), tableHeader("Trailer Check-In Date"), tableHeader("Trailer Dispatch Date"), tableHeader("Actual Ship Date"), tableHeader("Dest. Trailer Check-In Date"), tableHeader("Dest. Trailer Check-Out Date"), tableHeader("Need by Date"), tableHeader("Expected Ship by Date"), tableHeader("Order Type"), tableHeader("Order Status"), tableHeader("Qty Ordered"), tableHeader("Qty Shipped"), tableHeader("Truck Number"), tableHeader("PO Value"), tableHeader("PO Value Currency"), tableHeader("UOM Shipped"), tableHeader("Cartons Shipped"), tableHeader("Pallets Shipped")]), createElement("tr", {
                        children: Interop_reactApi.Children.toArray(Array.from(children)),
                    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])))), createElement("tbody", createObj(singleton((elems_2 = toList(delay(() => map_2((item) => {
                        let elems_1;
                        return createElement("tr", createObj(singleton((elems_1 = [tableData(formatInt(item.WmsOrderNo)), tableData(item.ExternalOrderNo), tableData(item.TrackingIdProNo), tableData(item.Priority), tableData(formatDateTime(item.PriorityUpdate)), tableData(item.OdmId), tableData(item.OdmName), tableData(item.SupplierId), tableData(item.SupplierName), tableData(formatDateTime(item.OrderAddDate)), tableData(formatDateTime(item.AllocationDate)), tableData(formatDateTime(item.StagedDate)), tableData(formatDateTime(item.TrailerCheckInDate)), tableData(formatDateTime(item.TrailerDispatchDate)), tableData(formatDateTime(item.ActualShipDate)), tableData(formatDateTime(item.DestTrailerCheckIn)), tableData(formatDateTime(item.DestTrailerCheckOut)), tableData(formatDateTime(item.NeedbyDate)), tableData(formatDateTime(item.ExpShipDate)), tableData(item.OrderType), tableData(item.OrderStatus), tableData(formatDecimal(item.QtyOrdered)), tableData(formatDecimal(item.QtyShipped)), tableData(item.TruckNumber), tableData(formatDecimal(item.PoValue)), tableData(item.PoValueCurrency), tableData(item.UomShipped), tableData(formatDecimal(item.CartonsShipped)), tableData(formatDecimal(item.PalletsShipped))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]))));
                    }, pagedData.Data))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))));
                }
            }
            default:
                return singleton_1(createElement("div", {
                    className: join(" ", ["has-text-centered"]),
                    children: "Select filters to run the query",
                }));
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])));
}

export function renderRunReport(state, dispatch) {
    let elems_4, elems_3, elems_2, elems_1, elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_4 = [createElement("div", {
        className: join(" ", ["column"]),
    }), createElement("div", {
        className: join(" ", ["column"]),
    }), createElement("div", {
        className: join(" ", ["column"]),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_2 = [createElement("div", {
        className: join(" ", ["column"]),
    }), createElement("div", {
        className: join(" ", ["column"]),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_1 = [createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-primary", "is-small"])], ["type", "button"], ["onClick", (_arg) => {
        dispatch(new Msg(0, [new AsyncMsg$1(0, [])]));
    }], (elems = [createElement("div", {
        children: "Run Report",
    }), createElement("br", {})], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
}

export function showLoadingSpinner(state) {
    if (equals(state.PagedDataDeferred, new Deferred$1(1, []))) {
        return true;
    }
    else {
        return equals(state.ExportDeferred, new Deferred$1(1, []));
    }
}

export function render(state, dispatch) {
    let elems_5, elems_1, elems, elems_4, elems_3, elems_2, matchValue_1, pagedData;
    if (showLoadingSpinner(state)) {
        return render_6();
    }
    else {
        return createElement("div", createObj(singleton((elems_5 = [createElement("div", createObj(ofArray([["className", "columns"], (elems_1 = [createElement("div", {
            className: join(" ", ["column"]),
        }), createElement("div", {
            className: join(" ", ["column"]),
        }), createElement("div", createObj(ofArray([["className", join(" ", ["column", "has-text-right"])], (elems = [createElement("a", {
            className: join(" ", ["has-link"]),
            href: "#/scheduled-reports",
            children: "Scheduled Reports",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", "box"], (elems_4 = [createElement("h1", {
            className: join(" ", ["has-text-link", "has-text-weight-semibold"]),
            children: "Outbound Summary Query",
        }), createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-flex", "is-align-items-center"])], (elems_2 = [createElement("h1", {
            className: join(" ", ["has-text-link"]),
            children: (matchValue_1 = state.PagedDataDeferred, (matchValue_1.tag === 1) ? "Searching..." : ((matchValue_1.tag === 2) ? ((pagedData = matchValue_1.fields[0], `${pagedData.TotalCount} items found | showing page ${state.Page} of ${pagedData.NumberOfPages}`)) : "")),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), renderRow1(state, dispatch), renderRow2(state, dispatch), renderRow3(state, dispatch), renderRow4(state, dispatch), renderRunReport(state, dispatch)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), renderRowReport(state, dispatch)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))]))));
    }
}

